<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="mb-2">
          <h3>
            ออเดอร์
          </h3>
        </div>
        <div class="row ">
          <div class="col-12 col-sm-4 col-xl-3">
            <b-form-group
              label="เริ่มต้น"
              label-for="vi-first-name"
            >
              <DatePicker
                :date="inputs.dateStart"
                :type="'start'"
                :status="false"
                @setDatePicker="onSetDatePicker"
              />
            </b-form-group>
          </div>
          <div
            class="col-12 col-sm-4 col-xl-3"
          >
            <b-form-group
              label="
            สิ้นสุด"
              label-for="vi-first-name"
            >
              <DatePicker
                :date="inputs.dateEnd"
                :type="'end'"
                :status="false"
                @setDatePicker="onSetDatePicker"
              />
            </b-form-group>
          </div>
          <div class="col-12 col-sm-4 align-self-center mb-1">
            <b-form-checkbox
              v-model="inputs.checkedList"
              checked="true"
              name="check-button"
              switch
              inline
            >
              ทั้งหมด
            </b-form-checkbox>

          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col-12 col-sm-4 col-xl-3 ">

            <b-form-select
              v-model="inputs.status"
              class="mb-1"
              :options="opption"
            />
          </div>

        </div>
        <b-table
          striped
          hover
          responsive
          class="type-relative"
          :per-page="inputsnode.perPage"
          :current-page="inputsnode.currentPage"
          :items="listOrder"
          :fields="fields"
          :filter="searchTerm"
          show-empty
          @filtered="onFiltered"
        >

          <template #cell(date)="data">
            <p>{{ data.item.created_at | formatDate }}</p>
          </template>
          <template #cell(price)="data">
            <p>{{ data.item.totalPrice.$numberDecimal | toCurrency }}</p>
          </template>
          <template #cell(status)="data">
            <p :class="data.item.status === 'i'?'text-primary':data.item.status === 'c'?'text-danger':data.item.status === 's'?'text-warning':data.item.status === 'a'?'text-info':'text-success'">
              {{ data.item.status === 'i'?'รอการจัดการ':data.item.status === 'c'?'ยกเลิก':data.item.status === 's'?'จัดส่ง':data.item.status === 'a'?'รอการชำระ':'อนุมัติ' }}
            </p>
          </template>
          <template #cell(button)="data">
            <div class="d-grid d-md-block">
              <button
                class="btn btn-primary mr-1"
                type="button"
                @click="onClickManagement(data.item._id)"
              >
                จัดการ
              </button>
              <button
                v-if="data.item.status === 'a' || data.item.status === 'n'"
                class="btn btn-info mr-1"
                type="button"
                @click="onClickPayment(data.item._id,data.item.status)"
              >
                ชำระ
              </button>
              <button
                v-if="data.item.status !== 'i' && data.item.status !== 'a' && data.item.status !== 'c'"
                class="btn btn-warning mr-1"
                type="button"
                @click="onclickTacking(data.item._id,data.item.transportNo, data.item.transportBy)"
              >
                จัดส่ง
              </button>
            </div>
          </template>
          <template #empty>
            <div class="col-md-12 text-center m-1">
              <h4>ไม่พบข้อมูลการขาย</h4>
            </div>
          </template>
          <template #emptyfiltered>
            <div class="col-md-12 text-center m-1">
              <h4>ไม่พบข้อมูลการขาย</h4>
            </div>
          </template>
        </b-table>
        <b-card-body
          class="d-flex justify-content-between flex-wrap pt-0 mt-2"
        >

          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="inputsnode.perPage"
              size="sm"
              inline
              :options="inputsnode.pageOptions"
            />
          </b-form-group>

          <div>
            <b-pagination
              v-model="inputsnode.currentPage"
              :total-rows="inputsnode.totalRows"
              :per-page="inputsnode.perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>

      </div>

    </div>
    <b-modal
      id="modal-center"
      ref="my-modal"
      v-model="IsopenModalTack"
      hide-footer
      centered
      header-bg-variant="primary"
      title="จัดส่ง"
    >
      <Transport
        :data="datatack"
        @successTack="emitTack"
      /></b-modal>

  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow, BCol, BTable, BFormGroup, BFormSelect, BPagination, BModal, BButton, VBModal, BCardBody, BFormInput, BFormCheckbox, BBadge
  , BForm
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import moment from 'moment'
import DatePicker from '@/views/components/production/datePicker.vue'
import Transport from './transport.vue'

export default defineComponent({
  name: 'orderlist',

  components: {
    BRow,
    BCol,
    BTable,
    BButton,
    BFormSelect,
    BPagination,
    BCardBody,
    BFormGroup,
    BCardCode,
    BModal,
    VBModal,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    required,
    DatePicker,
    BFormCheckbox,
    BBadge,
    Transport
  },
  watch: {
    'inputs.checkedList': {
      handler (val) {
        this.getListOrder()
      }
    },
    'inputs.dateStart': {
      handler (val) {
        this.getListOrder()
      }
    },
    'inputs.dateEnd': {
      handler (val) {
        this.getListOrder()
      }
    },
    'inputs.status': {
      handler (val) {
        this.getListOrder()
      }
    }
  },
  created () {
    this.getListOrder()
  },
  methods: {
    setReqiure () {
      this.$store.commit('order/SET_TOTAL_orderRequire')
    },
    onclickTacking (code, transportNo, transportBy) {
      this.datatack.code = code
      this.datatack.transportNo = transportNo
      this.datatack.transportBy = transportBy

      this.IsopenModalTack = true
    },
    closeTacking () {
      this.IsopenModalTack = false
    },
    emitTack (e) {
      if (e) this.getListOrder()
      this.closeTacking()
    },
    onClickManagement (code) {
      this.$router.push({ path: `ordermanagement/${code}` })
    },
    onClickPayment (code, status) {
      this.$router.push({ path: `orderpayment/${code}/${status === 'a' ? 'add' : 'edit'}` })
    },
    onSetDatePicker (data) {
      if (data.type === 'start') this.inputs.dateStart = moment(data.val).format('YYYY-MM-DD'); else this.inputs.dateEnd = moment(data.val).format('YYYY-MM-DD')
    },
    onFiltered (filteredItems) {
      this.inputsnode.totalRows = filteredItems.length
      this.inputsnode.currentPage = 1
    },
    getListOrder () {
      const status = this.inputs.status === '' ? ['i', 'a', 'n', 'c', 's'] : [this.inputs.status]
      this.$store.dispatch('order/getOrder', { startDate: this.inputs.checkedList ? '' : this.inputs.dateStart, endDate: this.inputs.checkedList ? '' : this.inputs.dateEnd, status }).then(res => {
        if (res.data.success) {
          this.listOrder = res.data.items
          this.inputsnode.totalRows = this.listOrder.length
        }
      })
    }
  },
  data () {
    return {
      IsopenModalTack: false,
      listOrder: [],
      searchTerm: '',
      inputs: {
        dateStart: moment().format('YYYY-MM-DD'),
        dateEnd: moment().format('YYYY-MM-DD'),
        checkedList: false,
        status: 'i'
      },
      opption: [{ value: '', text: 'ทั้งหมด' }, { value: 'i', text: 'รอการจัดการ' }, { value: 'n', text: 'อนุมัติ' }, { value: 'a', text: 'รอการชำระ' }, { value: 's', text: 'จัดส่ง' }, { value: 'c', text: 'ยกเลิก' }],
      fields: [
        {
          key: 'code', label: 'รหัสออเดอร์', thStyle: 'min-width: 165px', sortable: true
        },
        {
          key: 'date', label: 'วันที่-เวลา', thStyle: 'min-width: 240px'
        },
        {
          key: 'price', label: 'ราคา', thStyle: 'min-width: 170px', tdClass: 'text-right', thClass: 'text-right'
        },
        {
          key: 'status', label: 'สถานะ', thStyle: 'min-width: 140px'
        },
        { key: 'button', label: '', thStyle: 'min-width: 340px', tdClass: 'text-center', thClass: 'text-center' }

      ],
      inputsnode: {
        perPage: 5,
        pageOptions: [5, 10, 15],
        totalRows: 0,
        itemsPerPage: 10,
        currentPage: 1,
        startItem: 0,
        endItem: 10
      },
      datatack: {
        code: '',
        transportNo: '',
        transportBy: ''
      }
    }
  }
})
</script>
<style >
.modal-title {
  color: white;
}
</style>
